<template>
	<div class="vheader">
		<div class="back">
			<van-icon name="arrow-left" class="back_van" @click="toback" />
		</div>
		<div class="title">
			<div class="title_text">{{title}}</div>
		</div>
	</div>
</template>
<script type="text/ecmascript-6">
	export default {
		name: 'vheader',
		props: {
			title: {
				type: String,
				default: ''
			}
		},
		methods: {
			toback() {
				this.$emit('back')
			}
		}
	}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
	.vheader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #29c4da;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: flex-start;	

		.back {
			float: left;
			height: 45px;
			width: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.back_van {
			font-size: 25px;
		}

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.title_text {
			font-size: 18px;
			color: #ffffff;
		}
	}
</style>
