<template>
	<div class="protocol">
		<v-header :title="$t('C.PhoneProtocol')" @back="toback"></v-header>
		<div class="container">
			<iframe :src="agreementContent" frameborder="0" height="100%" width="100%">
			</iframe>
		</div>
	</div>
</template>

<script type="text/ecmascript-6">
	import VHeader from 'components/v-header/v-header'
	export default {
		name: 'customerProtocol',
		data() {
			return {
				agreementContent: ''
			};
		},
		mounted() {
			// let mid = this.$route.query.mid; // 材质
			// let bLang = this.$route.query.bLang; // 语言
			// let protocol = this.$route.query.protocol; // 协议
			// if (mid == 1) {
			// 	if (bLang == "he") {
			// 		this.agreementContent = 'http://print-h5.rockb2b.com/h5/protocolSkinHe.html'
			// 	} else {
			// 		this.agreementContent = 'http://print-h5.rockb2b.com/h5/protocolSkin.html'
			// 	}
			// } else {
			// 	this.agreementContent = 'http://print-h5.rockb2b.com/h5/protocolCase.html'
			// }
			if(this.$route.query.protocol){
				this.agreementContent = this.$route.query.protocol
			}
		},
		methods: {
			toback() {
				let mid = this.$route.query.mid; // 材质
				let back = this.$route.query.back; // 返回界面
				let devSn = this.$route.query.devSn; // sn
				if (back === 'home') {
					this.$router.push({
						path: '/home',
						query: {
							sn: devSn,
							mid: mid
						}
					})
				} else {
					if (mid == 1) {
						this.$router.replace("/custom");
					} else if (mid == 2) {
						this.$router.replace("/hotCustom");
					}
				}
			}
		},
		components: {
			VHeader
		}
	}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
	@import '~common/styles/variable'
	@import "~common/styles/mixin"

	.protocol {
		position: fixed;
		width: 100%;
		top: 45px;
		bottom: 0;
		background-color: #ffffff;
		z-index: 99;

		.container {
			background-color: #ffffff;
			height: 100%;
			width: 100%;
		}
	}
</style>
